@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

*,
::before,
::after {
  border-color: #e2e8f0;
  overflow-wrap: break-word;
}

*,
::before,
::after {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #1a202c;
  background: #000000;
  transition-property: background-color;
  transition-duration: 200ms;
  line-height: 1.5;
  background: url(./images/background.png) #000000;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.brand-logo {
  width: 100%;
  max-width: 200px;
  height: auto;
}

.btn-primary {
  color: #000;
  background: #12f1c3;
  font-weight: 500;
}

.banner-section {
  padding: 50px 50px;
  margin-bottom: 100px;
}

.title {
  text-transform: uppercase;
  font-size: 3rem;
  color: #12f1c3;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--chakra-line-clamp);
  --chakra-line-clamp: 1;
  text-shadow: 0 5px 5px rgb(0 0 0);
}

.sub-title {
  margin-top: 0.5rem;
  margin-inline: 0px;
  margin-bottom: 0px;
  color: #fff;
  text-shadow: 0 5px 5px rgb(0 0 0);
}

.card {
  background: #000000ad;
  border-radius: 10px;
  padding: 1.25rem;
}

.form-control {
  background: transparent;
  border-color: #12f1c3;
  color: #fff;
}

.form-label {
  color: #fff;
}

.form-control:focus {
  background: transparent;
  border-color: #12f1c3;
  outline: none;
  box-shadow: none;
  color: #fff;
}

.input-group-text {
  background: #12f1c3;
  border-color: #12f1c3;
}

.footer {
  background: #000000;
  color: #FFF;
  padding: 0.75rem;
  margin-top: 14px;
  /* margin-bottom: 14px; */
  position: fixed;
  /* height: 100px; */
  bottom: 0;
  width: 100%;
}

.footer p {
  margin: 0;
}

.wallet-box {
  border: 1px solid #12f1c3;
  border-radius: 10px;
  padding: 0.5rem;
  text-align: center;
  min-height: 126px;
}

.wallet-box img {
  max-width: 60px;
  width: 100%;
  height: auto;
}

.wallet-box p {
  margin: 0;
}

.info-card {
  margin: 0px 30px 0px 10px;
}

/* .sub-title {
  width: 60%;
} */


@media only screen and (max-width: 600px) {
  .info-card {
    margin: 5px;
  }

  /* .sub-title {
    width: 90%;
  } */

  .banner-section {
    padding: 10px 10px;
  }

  .brand-logo {
    width: 65%;
    max-width: 100px;
    height: auto;
  }

  .navbar-expand-lg {
    height: auto;
  }

  .title {
    font-size: 1.5rem;
  }
}


.flex-1 {
  flex: 1 1;
}

.border-brand-default,
.dark .border-brand-default {
  border-color: #4f87f6;
}

.bg-brand-default,
.dark .bg-brand-default {
  background-color: #4f87f6;
}

.transition {
  transition-duration: .15s;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.border {
  border-width: 1px;
}

.rounded-full {
  border-radius: 9999px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}



.border-brand-default,
.dark .border-brand-default {
  border-color: #12f1c3 !important;
}

.bg-brand-default,
.dark .bg-brand-default {
  background-color: transparent;
}

.transition {
  transition-duration: .15s;
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.text-center {
  text-align: center;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-2 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.border {
  border-width: 1px;
}

.rounded-full {
  border-radius: 9999px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

.mb-8 {
  margin-bottom: 2rem;
}

.modal-content {
  background-color: #000;
  color: #FFF;
}

.btn-close {
  background: transparent url('./images/close.svg') center/1em auto no-repeat;
}

#connect-wallet-button:hover {
  background-color: #027f7c;
  /* color: #000; */
}

.card-body {
  padding: 7px;
}

.css-b62m3t-container {
  margin-bottom: 12px;
}

.react-select-3-input {
  height: 0 !important;
}

.css-1fdsijx-ValueContainer {
  height: 50px;
  display: flex !important;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {

  background-color: #12f1c3;
  border-color: #12f1c3;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: #12f1c3;
  border-color: #12f1c3;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: #12f1c3;
  border-color: #12f1c3;
}

.nav-item {
  margin: 12px;
}

.navbar-nav {
  align-items: center;
}

.bg-dark {
  background-color: #000000a8 !important;
}

.dropdown-toggle::after {
  display: none;
}

.text-decoration-none {
  text-decoration: none !important;
}

/* Font Awesome Icons have variable width. Added fixed width to fix that.*/
.icon-width {
  width: 2rem;
}

@media only screen and (max-width: 800px) {
  .brand-logo{
    width: 70%;
  }
}

@media only screen and (min-width: 992px){
  .navbar-expand-lg{
    height: 90px;
  }
}

.btn:hover {
  background-color: #000000 !important;
  border-color: #12f1c3 !important;
}